@import "../variables";

@media only screen and (max-width: 640px) {
  .split-container {
    flex-direction: column !important;
  }
}

.travel-accommodation-sections-container,
.modal-custom {
  section {
    // background-color: $theme7;
    // width: 100vw;
    max-width: 900px;
    padding: 0 10px;
    margin: 0 auto;
  }
  h2 {
    font-size: 1.6rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-bottom: 25px;
    padding-top: 30px;
  }
}

.split-container {
  display: flex;
  flex-direction: row;

  div {
    // border: 2px solid red;
    flex: 1 0 0;
    padding: 20px;
    text-align: justify;
    display: flex;
    justify-content: center;
  }
}

.modal-custom {
  text-align: center;
  max-width: 1000px;
  padding: 0 20px;
  .modal-content {
    border-radius: 0;
    border: 1px solid hsla(146, 17%, 62%, 0.202);
    outline: none;
  }
}
.modal-backdrop {
  background-color: $theme10;
}
