@import "../variables";

@media only screen and (max-width: 750px) {
  .navbar-toggle-text {
    display: none;
  }
}
.custom-toggle-button {
  display: flex;
  align-items: center;
}

.offcanvas-title {
  font-size: 2rem;
  color: $theme5;
  margin-left: 15px;
}

.custom-toggle {
  font-size: 1.2rem;
  background-color: $mainBackgroundColour; //$theme3;
  opacity: 1;
  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
  border: none;
  padding: 4px 6px;
  // border: 4px solid $theme7 !important;
  border-radius: 2px;
  transition: 0.2s;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2) !important;
    background-color: rgb(249, 249, 249);
  }
}
.navbar {
  top: -71px;
  position: relative;
  float: right !important;
  display: inline;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}

.active-nav {
  &::before {
    content: "";
    background-image: url("../assets/heart.png");
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: top left;
    width: 50px;
    height: 50px;
    // padding-left: 10px;
    font-size: 2.1rem;
    position: absolute;
    top: 11px;
    left: -22px;
  }
  // opacity: 0.8;
  color: $theme4;
  font-weight: bold;
  // text-decoration: underline;
}

.custom-link {
  position: relative;
  font-size: 1.5rem;
  color: $theme4;
  text-decoration: none;
  margin-bottom: 15px;
  margin-left: 15px;
  transition: 0.2s;

  &:hover {
    color: $theme5;
  }
  &:focus,
  &:active {
    color: $theme4;
  }
}
