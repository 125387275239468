@import "../variables";

.coming-soon-header {
  color: $theme5;
  background-color: $theme7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  // color: white;

  h3 {
    margin-top: 10px;
  }
}
