@import "../variables";

.hero-image {
  max-width: 100vw;
}

.section {
  padding: 2rem 10px;
  color: $theme5;
  //   border-top: 1.5px dotted $theme2;
}

.section-title {
  // font-family: "Savoye" !important;
  // font-size: 3rem;
  // color: $theme8;
  font-size: 2.1rem;
  margin-bottom: 1rem;
}

.odd {
  //   background-color: $theme2;
}

.even {
  //   background-color: $theme1;
}

.poem-caption {
  font-size: 1.1rem;
  max-width: 600px;
  margin: 15px auto;
}

.quote {
  color: $theme4;
  font-size: 2.5rem;
  padding: 0 5px;
  margin: 0;
  line-height: 0.5;
}

.poem {
  color: $theme4;
  // font-style: italic;

  font-size: 2.4rem;
  // font-size: 1.5rem;
}

.timeline-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  //   display: grid;
  //   grid-template-columns: 1fr;
  //   grid-template-rows: auto 5em min-content auto;
}

.event {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  color: $theme5; //$theme8;
  background-color: rgb(252, 252, 252); //$mainBackgroundColour; //$theme6;
  border: 2px solid rgba(233, 233, 233, 0.4);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
  margin: 10px;
  transition: 0.2s;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  will-change: transform;

  // &:hover {
  //   // cursor: pointer;
  //   transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
  // }

  //   &:nth-of-type(2n-1) {
  //     color: $theme1;
  //   }
}

.accommodation-item {
  flex-direction: column;
}
.todo-item {
  display: inline-block;
  text-align: center;
}

.event-time {
  font-weight: bold;
}
.event-details {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  text-align: left;
}

.heart-separator {
  background-image: url("../assets/heart.png");
  background-repeat: space;
  background-size: contain;
  opacity: 0.4;
  height: 15px;
  width: 100%;
}

.main-image-container {
  // background-image: url("../assets/stepping-stones.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // margin: 0 auto;

  // width: 100vw;
  // min-height: 600px;
}
