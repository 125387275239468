@import "../variables";

.faq-row-wrapper {
  max-width: 600px;
  margin: 0 auto;
}

.faq-title {
  display: none !important;
}

.row-title {
  text-align: left;
}

.faq-body {
  text-align: justify !important;
}

.faq-row {
  // QUESTION TITLE
  color: $theme5 !important;
}

.row-content {
  // QUESTION ANSWER
  white-space: normal;
  color: $theme5 !important;
  opacity: 0.8;
}

.arrow-image {
  svg {
    fill: $theme5 !important;
  }
}

.faq-container {
  margin: 0 20px;
}
