@import "../variables";

.faq-row-wrapper {
  max-width: 600px;
}

.faq-title {
  display: none !important;
}

.row-title {
  text-align: left;
}

.faq-body {
  text-align: justify !important;
}

.faq-row {
  // QUESTION TITLE
  color: $theme5 !important;
}

.row-content {
  // QUESTION ANSWER
  white-space: normal;
  color: $theme5 !important;
  opacity: 0.8;
}

.arrow-image {
  svg {
    fill: $theme5 !important;
  }
}

.faq-container {
  margin: 0 20px;
}

.hero-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.dropbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 20px;
  height: 80%;
}
