@import "../variables";

.page-title {
  color: $theme5;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;

  h1 {
    font-size: 2rem;
    margin-block-end: 0.75em;

    span {
      padding-bottom: 1px;
      border-bottom: 2px solid $theme5;
    }
  }
}
