@import "variables.scss";

@font-face {
  font-family: "Savoye";
  src: url("./fonts/savoye_regular-webfont.woff2") format("woff2"), url("./fonts/savoye_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  background-color: $mainBackgroundColour;
  font-size: 16px;
}

.theme-4 {
  color: $theme4 !important;
}
.theme-5 {
  color: $theme5 !important;
}
.theme-7 {
  color: $theme7 !important;
}

* {
  font-family: "Alice", sans-serif !important;
  --bs-body-font-family: "Alice", sans-serif !important;
}

.fancy-font {
  font-family: "Savoye" !important;
}

.App {
  text-align: center;
}

.text-justify {
  text-align: justify;
}
.wrapper {
  position: relative;
  top: 110px;
  width: 100%;
  padding-bottom: 80px;
}

.page,
.modal-custom {
  color: $defaultPageTextColour;
  font-weight: 100;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
}

.btn-custom {
  color: #fff;
  background-color: $defaultButtonColour;
  transition: 0.2s;
  &:hover {
    cursor: pointer !important;
    color: #fff;
    opacity: 0.8;
  }

  &.btn-muted {
    background-color: #98aaa0;
  }
}

.hide {
  display: none;
}
.hide-soft {
  transition: 0.2s;
  opacity: 0;
}

.max-width-400 {
  max-width: 400px !important;
}

a.defaultLink {
  color: $theme5;
  text-decoration: none;
  border-bottom: 2px solid $theme4;
  transition: 0.2s;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// CUSTOM INPUT

input[type="radio"] {
  // display: none;
  position: relative;
  left: 34px;
  top: 3px;

  // outline: $theme4 !important;
}

.form-check {
  text-align: left !important;
  display: inline-block;
}

@media only screen and (max-width: 362px) {
  input[type="radio"] + label {
    margin-bottom: 7px;
  }
}
input[type="radio"] + label {
  border-radius: 5px;
  // padding: 3px 5px 4px 5px;
  padding: 3px 15px 4px 32px;
  background-color: $theme6;
  transition: 0.2s;
  // opacity: 0.7;
  &:hover {
    cursor: pointer;
    background-color: $theme11;
  }
}
input[type="radio"] {
  &:checked {
    background-color: $theme4 !important;
    outline: $theme4 !important;
    border-color: $theme4 !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(59, 133, 42, 0.2);
  }
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
button {
  &:focus {
    outline: $theme4 !important;
    border-color: $theme4 !important;
    box-shadow: 0 0 0 0.25rem rgba(59, 133, 42, 0.2) !important;
  }
}

.offcanvas-end {
  border: none !important;
}

.offcanvas-backdrop {
  // background-color: $theme5;
}
.offcanvas-body {
  padding: 2rem;
}

.offcanvas-header {
  justify-content: flex-end !important;
  // margin: 30px 25px 0px 0px;
}

.btn-close {
  color: $defaultPageTextColour !important;
}
