@import "../variables";

@supports (-webkit-tap-highlight-color: black) {
  /* Matches Edge 12 - latest (92) */
  /* Matches Chrome 16 - latest (95) */
  /* Matches Opera 15 - latest (78) */

  /* does not match Safari */
  /* does not match Firefox */

  .heading-logo {
    image-rendering: -webkit-optimize-contrast !important;
  }
}

@media only screen and (max-width: 640px) {
  .heading-logo {
    height: 60px !important;
    font-size: 2.1rem !important;
  }
}

@media only screen and (max-width: 490px) {
  .heading-logo {
    height: 55px !important;
  }
  .heading-logo-text h1.fancy-font {
    font-size: 50px !important;
  }
  .main-heading {
    // justify-content: left !important;
    // align-items: baseline !important;
    // span {
    //   font-size: 1.9rem !important;
    // }
  }
}
@media only screen and (max-width: 424px) {
  .main-heading {
    // justify-content: left !important;
    // align-items: baseline !important;
    // padding-left: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .heading-logo {
    height: 45px !important;
  }
  .main-heading {
    // justify-content: left !important;
    align-items: baseline !important;
  }
}

.top-bar-container {
  // background-color: $theme6;
  position: fixed;
  width: 100%;
  display: block;
  z-index: 1000;
}

.heading-logo {
  height: 70px;
}
.heading-logo-text,
.heading-logo-text:hover,
.heading-logo-text:focus,
.heading-logo-text:active {
  position: relative;
  // height: 70px;
  text-decoration: none !important;
  color: $theme5;
  border: none;
  font-style: none;
}

.top-bar {
  // background-image: url("../assets/leaves.png"),
  //   url("../assets/leaves-flipped.png");

  background-size: 220px, 220px;
  background-repeat: no-repeat;
  background-position: left top, right top;
  display: block;
  position: fixed;
  z-index: 1000;

  width: 100%;

  background-color: $mainBackgroundColour; //$theme6;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 120px;
  padding: 10px;
  color: $theme5;
}

.main-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  margin-block-end: 0em;
  // padding-top: 12px;
  div {
    display: flex;
    flex-direction: column;
  }
  h1 {
    font-size: 2.5rem;

    margin-block-end: -0.2em;
    // margin-block-start: 0.33em;
  }
  span {
    margin-top: 0px;
    font-size: $fontXLarge;
  }
}
