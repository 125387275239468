$theme1: #8babaa;
$theme2: #abc8b2;
$theme3: #b8c5aa;
$theme4: #8cae9b; //#588d70; // ***
$theme5: #3f6550; // ***
$theme6: #edf4f3;
$theme7: #dfebe5; // ***
$theme8: #789c9b;
$theme9: #c5d1ca;
$theme10: #c5d0ca;
$theme11: #f9fffa;
$theme12: #ebf1ee;

$defaultPageTextColour: $theme5; // #464646
$defaultButtonColour: $theme4; //#949494;

$fontXSmall: 0.4rem;
$fontSmall: 0.7rem;
$fontNormal: 1rem;
$fontLarge: 1.3rem;
$fontXLarge: 1.8rem;

$mainBackgroundColour: #f9fbfa;
