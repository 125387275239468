@import "../variables";

.countdown {
  color: $theme4; // $theme1; 2 or 4
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.countdown-num {
  display: inline-block;
  font-size: 3rem;
  width: 45px;
  text-align: right;
}

.countdown-label {
  margin-left: 8px;
  margin-right: 15px;
}
